import React, { Component } from "react";
import PropTypes from "prop-types";
import Markdown from "markdown-to-jsx";
import InputTextField from "./Input/InputTextField";
import TextAreaField from "./Textarea/TextAreaField";
import DropdownSelect from "./Dropdown/DropdownSelect";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { theme } from "../../../utils/global-styles/theme";
import Checkbox from "../../shared/Checkbox/Checkbox";

const StyledForm = styled("form")`
    display: grid;
    transform: ${props => (props.skewed ? "skew(10deg)" : "skew(0deg)")};
  `,
  StyledButton = styled("button")`
    :not(:disabled) {
      &:hover {
        cursor: pointer;
      }
    }
  `,
  StyledRegisterFinePrint = styled(Markdown)`
    display: block;
    color: ${theme.colors.main};
    font-size: 19.5px;
    font-family: ${theme.fontFaces.help};
    margin: 20px 0;
    line-height: 1.5em;
    a {
      font-weight: bold;
    }
  `,
  GroupedFormValuesWrapper = styled("div")`
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 1em;
  `,
  GroupedButtonAndFinePrint = styled("div")`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  `;

export default class DynamicForm extends Component {
  staticProps = {
    skewed: PropTypes.bool,
    testId: PropTypes.string,
    confirmButton: PropTypes.shape({
      className: PropTypes.string,
      buttonStyle: PropTypes.string,
      buttonText: PropTypes.string,
      buttonTextStyles: PropTypes.objectOf(PropTypes.string),
      disabled: PropTypes.bool,
    }),
    cancelButton: PropTypes.shape({
      buttonStyle: PropTypes.string,
      buttonText: PropTypes.string,
      buttonTextStyles: PropTypes.objectOf(PropTypes.string),
    }),
    submit: PropTypes.func,
    cancel: PropTypes.func,
    formValues: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        label: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.string),
        value: PropTypes.string,
        inputType: PropTypes.string,
        inputSubType: PropTypes.string,
        required: PropTypes.bool,
        helpText: PropTypes.string,
        handleValidation: PropTypes.func,
        error: PropTypes.string,
        placeholder: PropTypes.string,
        dataTestId: PropTypes.string,
      })
    ),
    formStyles: PropTypes.objectOf(PropTypes.string),
    finePrint: PropTypes.string,
    finePrintClassName: PropTypes.string,
    finePrintStyles: PropTypes.string,
    key: PropTypes.string,
    formErrors: PropTypes.node,
  };

  constructor(props) {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.props.formValues.forEach(value => {
      if (value.inputType === "checkbox") {
        this.setState({ [value.name]: value.value || false });
      } else {
        this.setState({ [value.name]: value.value || "" });
      }
    });
  }
  handleChange = event => {
    if (event.currentTarget.type === "checkbox") {
      this.setState({
        [event.currentTarget.name]: event.currentTarget.checked,
      });
    } else {
      this.setState({
        [event.currentTarget.name]: event.currentTarget.value,
      });
    }
  };

  render() {
    const {
      submit,
      formStyles,
      formValues,
      confirmButton,
      cancelButton,
      cancel,
      finePrint,
      finePrintClassName,
      finePrintStyles,
      formErrors,
      skewed,
      groupedFormValues,
      groupedButtonAndFinePrint,
      title,
    } = this.props;

    return (
      <StyledForm
        className="payment-form-billing-address"
        skewed={skewed || false}
        onSubmit={e => {
          e.preventDefault();
          submit(e, this.state);
          // Clear values after submit
          formValues.forEach(value => {
            this.setState({ [value.name]: "" });
          });
        }}
        css={css`
          ${formStyles}
        `}
        name={formValues.name}
      >
        {formValues.map((formInput, index) => {
          switch (formInput.inputType) {
            case "text":
              return (
                <InputTextField
                  title={title}
                  name={formInput.name}
                  className={formInput.className}
                  placeholder={formInput.placeholder}
                  required={formInput.required}
                  label={formInput.label}
                  styles={formInput.styles}
                  handleChange={
                    formInput.handleChange
                      ? formInput.handleChange
                      : this.handleChange
                  }
                  handleValidation={formInput.handleValidation}
                  key={`${formInput.name}__${index}`}
                  value={
                    formInput.handleChange
                      ? formInput.value
                      : this.state[formInput.name]
                  }
                  type={formInput.inputSubType || "text"}
                  errorFromParent={formInput.error}
                  helpText={formInput.helpText}
                  dataTestId={formInput.dataTestId}
                />
              );
            case "textarea":
              return (
                <TextAreaField
                  type="text"
                  name={formInput.name}
                  required={formInput.required}
                  styles={formInput.styles}
                  placeholder={formInput.placeholder}
                  handleChange={formInput.handleChange}
                  key={index}
                  errorFromParent={formInput.error}
                />
              );
            case "dropdown":
              return (
                <DropdownSelect
                  label={formInput.label}
                  name={formInput.name}
                  required={formInput.required}
                  styles={formInput.styles}
                  placeholder={formInput.placeholder}
                  handleChange={formInput.handleChange}
                  values={formInput.values}
                  nullValue={formInput.nullValue || `Choose one`}
                  key={index}
                  errorFromParent={formInput.error}
                  downArrowIconClassName={formInput.downArrowIconClassName}
                  placeholderIcon={formInput.placeholderIcon}
                  fullscreenOnMobile={formInput.fullscreenOnMobile}
                  isMobile={formInput.isMobile}
                  labelClassNames={formInput.labelClassNames}
                  disabled={formInput.disabled}
                />
              );
            case "checkbox":
              return (
                <Checkbox
                  item={{
                    name: "join_mailing_list",
                    label: formInput.helpText,
                  }}
                  checked={this.state[formInput.name]}
                  onChange={e => this.handleChange(e)}
                />
              );
            default:
              return;
          }
        })}

        {groupedFormValues && (
          <GroupedFormValuesWrapper>
            {groupedFormValues?.map((formInput, index) => {
              switch (formInput.inputType) {
                case "text":
                  return (
                    <InputTextField
                      title={title}
                      name={formInput.name}
                      className={formInput.className}
                      placeholder={formInput.placeholder}
                      required={formInput.required}
                      label={formInput.label}
                      styles={formInput.styles}
                      handleChange={
                        formInput.handleChange
                          ? formInput.handleChange
                          : this.handleChange
                      }
                      handleValidation={formInput.handleValidation}
                      key={`${formInput.name}__${index}`}
                      value={
                        formInput.handleChange
                          ? formInput.value
                          : this.state[formInput.name]
                      }
                      type={formInput.inputSubType || "text"}
                      errorFromParent={formInput.error}
                      helpText={formInput.helpText}
                      dataTestId={formInput.dataTestId}
                    />
                  );
                case "textarea":
                  return (
                    <TextAreaField
                      type="text"
                      name={formInput.name}
                      required={formInput.required}
                      styles={formInput.styles}
                      placeholder={formInput.placeholder}
                      handleChange={formInput.handleChange}
                      key={index}
                      errorFromParent={formInput.error}
                    />
                  );
                case "dropdown":
                  return (
                    <DropdownSelect
                      label={formInput.label}
                      name={formInput.name}
                      required={formInput.required}
                      styles={formInput.styles}
                      placeholder={formInput.placeholder}
                      handleChange={formInput.handleChange}
                      values={formInput.values}
                      nullValue={formInput.nullValue || `Choose one`}
                      key={index}
                      errorFromParent={formInput.error}
                      downArrowIconClassName={formInput.downArrowIconClassName}
                      placeholderIcon={formInput.placeholderIcon}
                      fullscreenOnMobile={formInput.fullscreenOnMobile}
                      isMobile={formInput.isMobile}
                      labelClassNames={formInput.labelClassNames}
                      disabled={formInput.disabled}
                    />
                  );
                case "checkbox":
                  return (
                    <Checkbox
                      item={{
                        name: "join_mailing_list",
                        label: formInput.helpText,
                      }}
                      checked={this.state[formInput.name]}
                      onChange={e => this.handleChange(e)}
                    />
                  );
                default:
                  return;
              }
            })}
          </GroupedFormValuesWrapper>
        )}
        {!groupedButtonAndFinePrint && finePrint && (
          <StyledRegisterFinePrint
            className={finePrintClassName}
            css={css`
              ${finePrintStyles}
            `}
            options={{
              overrides: {
                a: {
                  props: {
                    className: "primary",
                  },
                },
              },
            }}
          >
            {finePrint}
          </StyledRegisterFinePrint>
        )}
        {formErrors || null}
        {!groupedButtonAndFinePrint && (
          <StyledButton
            data-testId={confirmButton.dataTestId}
            onMouseDown={e => e.preventDefault()}
            css={css`
              ${confirmButton.styles.buttonStyle}
              ${confirmButton.styles.buttonTextStyles}
            `}
            className={
              confirmButton.disabled
                ? `${
                    confirmButton.className ? confirmButton.className : ""
                  } disabled`
                : `${confirmButton.className ? confirmButton.className : ""} ${
                    confirmButton.confirmButtonClasses
                  }`
            }
            disabled={confirmButton.disabled}
            type="submit"
          >
            <div
              id="inner"
              css={css`
                ${confirmButton.styles.buttonTextStyles}
                transform: ${skewed ? "skew(-10deg)" : "none"};
              `}
            >
              {confirmButton.buttonText}
            </div>
          </StyledButton>
        )}
        {groupedButtonAndFinePrint && (
          <GroupedButtonAndFinePrint>
            <StyledButton
              onMouseDown={e => e.preventDefault()}
              data-testid={confirmButton.dataTestId}
              id="outer"
              css={css`
                ${confirmButton.styles.buttonStyle}
              `}
              className={
                confirmButton.disabled
                  ? `${confirmButton?.className || ""} disabled`
                  : `${confirmButton?.className || ""} ${
                      confirmButton.confirmButtonClasses
                    }`
              }
              disabled={confirmButton.disabled}
              type="submit"
            >
              <div
                id="inner2"
                css={css`
                  ${confirmButton.styles.buttonTextStyles}
                  transform: ${skewed ? "skew(-10deg)" : "none"};
                `}
              >
                {confirmButton.buttonText}
              </div>
            </StyledButton>
            <StyledRegisterFinePrint
              className={finePrintClassName}
              css={css`
                ${finePrintStyles}
              `}
              options={{
                overrides: {
                  a: {
                    props: {
                      className: "primary",
                    },
                  },
                },
              }}
            >
              {finePrint}
            </StyledRegisterFinePrint>
          </GroupedButtonAndFinePrint>
        )}
        {cancelButton && (
          <StyledButton
            onClick={cancel}
            data-testId={cancelButton.dataTestId}
            onMouseDown={e => e.preventDefault()}
            css={css`
              ${cancelButton.styles.buttonStyles}
            `}
            className={
              cancelButton.className
                ? `${cancelButton.className} ${cancelButton.cancelButtonClasses}`
                : cancelButton.cancelButtonClasses
            }
            
          >
            <div
              css={css`
                ${cancelButton.styles.buttonTextStyles}
                transform: ${skewed ? "skew(0deg)" : "none"};
              `}
            >
              {cancelButton.buttonText || "Cancel"}
            </div>
          </StyledButton>
        )}
      </StyledForm>
    );
  }
}
